<template>
  <el-drawer v-show="drawerDialog" direction="ltr" custom-class="drawerSlideBar" :visible.sync="drawerDialog" :before-close="beforeClose">
    <asideBar :showGoback="showGoback" :navMenuList="navMenuList" />
  </el-drawer>
</template>

<script>
import asideBar from '@/views/layout/components/aside'
export default {
  components: { asideBar },
  props: {
    showGoback: {
      type: Boolean,
      default: true
    },
    navMenuList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      drawerDialog: false
    }
  },
  watch: {
    '$store.state.isDrawer': {
      deep: true,
      handler(val) {
        this.drawerDialog = val
      }
    }
  },
  methods: {
    beforeClose() {
      if (this.drawerDialog) {
        this.$store.commit('toggleDrawer', false)
      }
    }
  }
}
</script>
<style lang="less">
.drawerSlideBar {
  transform: translate3d(0, 0, 0);
  .sidebar-nav {
    display: block;
  }
  .btn-anime1 {
    display: block;
    margin-top: 15px;
  }
  .sidebar-nav > * {
    transform: translate3d(0, 0, 0);
  }
  .el-drawer__header {
    display: none;
  }

  .sidebar-menu-box {
    height: 100vh;
  }

  .slide-bar-ul {
    .slide-bar span {
      display: block;
    }
  }
}
</style>
