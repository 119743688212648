<template>
  <div>
    <asideBar />
    <div
      class="mini-sidebar"
      :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }"
    >
      <div id="app">
        <div class="main-content flex-fill">
          <div class="placeholder"></div>
          <div class="header-big no-bg mt-0 mb-2 mb-md-3 pb-0 pt-2 pt-md-5">
            <div class="s-search m-md-0 px-md-5">
              <div
                id="search"
                class="s-search mx-auto"
              >
                <div class="searchDiv">
                  <el-autocomplete
                    class="inline-input"
                    v-model="searchValue"
                    :fetch-suggestions="querySearch"
                    placeholder="请输入关键字搜索"
                    :trigger-on-focus="false"
                  ></el-autocomplete>
                  <div
                    class="searchSlot"
                    @click="searchClick"
                  >
                    <i class="el-icon-search"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container customize-width px-md-5 mt-4 my-mt-5">
            <main class="content">
              <div class="content-wrap">
                <div class="content-layout">
                  <div class="mb-4">
                    <!-- <a class="btn btn-search mr-2 text-gray" href="?page=1&q=%E5%A3%81%E7%BA%B8&post_type=" title="有关“壁纸”的综合">综合</a
                  ><a class="btn btn-search mr-2 text-gray current" href="?page=1&q=%E5%A3%81%E7%BA%B8&post_type=sites" title="有关“壁纸”的网站"
                    >网站</a
                  ><a class="btn btn-search mr-2 text-gray" href="?page=1&q=%E5%A3%81%E7%BA%B8&post_type=post" title="有关“壁纸”的文章">文章</a
                  ><a class="btn btn-search mr-2 text-gray" href="https://jutusou.huamao.vip/s/?q=%E5%A3%81%E7%BA%B8" target="_blank">图片</a> -->
                    <a
                      v-for="item of tapList"
                      :key="item.key"
                      style="width: auto"
                      class="btn btn-search mr-2 text-gray  swiper-slide"
                      :class="actionStyle == item.key ? 'current' : ''"
                      @click="actionStyle = item.key"
                    >{{ item.title }}</a>
                  </div>
                  <h4 class="text-gray text-lg mb-4"><i class="iconfont icon-search mr-2"></i>“壁纸”&nbsp;的网站搜索结果</h4>
                  <div class="card-grid">
                    <div
                      class="url-card"
                      v-for="item in 3"
                    >
                      <div class="url-body">
                        <a class="url-content">
                          <div class="url-img">
                            <img
                              class="lazy"
                              src="../../assets/imgs/avatar.jpg"
                              data-src="//img2.huamaocdn.com/upload/daohang/favicon/huamaobizhi.com.png"
                              alt="花猫壁纸"
                            />
                          </div>

                          <div class="url-info">
                            <div class="overflowClip_1 mb-1 mb-md-2"><strong>花猫壁纸</strong><span class="badge">较多收藏</span></div>
                            <p class="overflowClip_2">
                              国内最良心的ACG类图片网站，所有资源免费下载；网站内容涵盖了游戏原画、日系插画、二次元插画、手绘、动漫、COS等作品。
                            </p>
                          </div>
                        </a>
                        <div class="url-link">
                          <div class="swiper-wrapper">
                            <a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >最新图片</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >热门图片</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >找画师</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >人物墙</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >标签</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >关闭安全过滤</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >电脑壁纸</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >手机壁纸</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >头像</a><a
                              href="/"
                              class="swiper-slide"
                              target="_blank"
                              rel="nofollow"
                            >喵小吉</a>
                          </div>
                        </div>
                        <a
                          href="/"
                          class="url-details"
                          target="_blank"
                          rel="nofollow"
                        ><i class="iconfont icon-goto"></i></a>
                      </div>
                    </div>
                  </div>

                  <div class="posts-nav mb-3 mb-md-4">
                    <span class="page-numbers current">1</span><a class="page-numbers">2</a><a class="page-numbers"><i class="iconfont icon-arrow-r"></i></a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>

<script>
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
export default {
  components: { asideBar, asideDrawer },
  data () {
    return {
      actionStyle: 'wz', // Popular 热门   new 最新
      tapList: [
        {
          title: '综合',
          key: 'zh'
        },
        {
          title: '网站',
          key: 'wz'
        },
        {
          title: '文章',
          key: 'wz2'
        },
        {
          title: '图片',
          key: 'img'
        }
      ],
      searchValue: '',
      restaurants: [
        { value: '三全鲜食（北新泾店）', address: '长宁区新渔路144号' },
        { value: 'Hot honey 首尔炸鸡（仙霞路）', address: '上海市长宁区淞虹路661号' },
        { value: '新旺角茶餐厅', address: '上海市普陀区真北路988号创邑金沙谷6号楼113' },
        { value: '泷千家(天山西路店)', address: '天山西路438号' },
        { value: '胖仙女纸杯蛋糕（上海凌空店）', address: '上海市长宁区金钟路968号1幢18号楼一层商铺18-101' }
      ]
    }
  },

  created () { },

  methods: {
    // 搜索方法
    querySearch (queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    // 点击搜索
    searchClick () {
      console.log('=========searchValue=========', this.searchValue)
    }
  }
}
</script>

<style scoped>
@import url("../css/bootstrap.css");
@import url("../css/style.css");
</style>
<style>
/* 搜索部分 */
.searchDiv {
  border: Red 1px solid;
  display: flex;
  border: #2254f4 2px solid;
  border-radius: 10px;
  overflow: hidden;
  height: 50px;
}

.searchDiv .el-autocomplete {
  width: calc(100% - 104px);
  height: 50px;
}

.searchDiv input {
  border: transparent 1px solid !important;
  height: 50px !important;
}

.searchDiv .el-input__inner::-webkit-input-placeholder {
  color: #888;
  font-size: 1rem;
}

.searchDiv .searchSlot {
  width: 104px;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  background: #2254f4;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
</style>
